<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="4"></user-left>
        </div>
        <div class="col-10 f-14">
          <div class="row bg-white rounded ml-2">
            <div class="p-3 border-bottom col-12 d-flex justify-content-between">
              <h6>我的订单</h6>

            </div>
            <div class="col-12 mt-3">
              <b-tabs lazy>
                <b-tab title="预约带看">
                  <div class="w-100">
                    <div class="clearfix mt-4 d-flex align-items-center screen">
                      <div class="float-left">下单时间：</div>
                      <div class="float-left ml-2">
                        <b-form-input type="date" v-model="yydkpara.param.startDate" @change="findReserve"></b-form-input>
                      </div>
                      <div class="float-left ml-2 mr-2">至</div>
                      <div class="float-left">
                        <b-form-input type="date" v-model="yydkpara.param.endDate" @change="findReserve"></b-form-input>
                      </div>
                      <div class="float-left ml-3">
                        <a class="ml-3" href="javascript:;" @click="createBeginDate(1,1)"
                           :class="{'active':yydkyues==1}">近一月</a>
                        <a class="ml-3" href="javascript:;" @click="createBeginDate(3,1)"
                           :class="{'active':yydkyues==3}">近三月</a>
                        <a class="ml-3" href="javascript:;" @click="createBeginDate(6,1)"
                           :class="{'active':yydkyues==6}">近半年</a>
                        <a class="ml-3" href="javascript:;" @click="createBeginDate(12,1)"
                           :class="{'active':yydkyues==12}">近一年</a>
                      </div>
                    </div>
                    <div class="clearfix mt-2 d-flex align-items-center screen">
                      <div class="float-left">订单状态：</div>
                      <div class="float-left ml-4">
                        <div class="row">
                          <a href="javascript:;" class="mr-3 px-2 py-1"
                             @click="yydkpara.param.reserveStatus = '', onScreenyydkClick('reserveStatus','')"
                             :class="{'active': yydkpara.param.reserveStatus == ''}">全部</a>
                          <a href="javascript:;" @click="onScreenyydkClick('reserveStatus',v.id)"
                             v-for="v in dict.reserveStatus" :key="v.id"
                             class="mr-3 px-2 py-1"
                             :class="{'active':yydkpara.param.reserveStatus==v.id }">{{ v.text }}</a>
                        </div>
                      </div>
                    </div>


                    <div class="card border-0 mt-3 mb-3">
                      <div class="card-body p-0">
                        <div class="table-responsive">
                          <table class="table table-centered border mb-0">
                            <thead class="table-light">
                            <tr>
                              <th class="w-70">订单信息</th>
                              <th>下单时间</th>
                              <th>状态</th>
                            </tr>
                            </thead>
                            <tbody v-if="ReserveList.records && ReserveList.records.length > 0">
                            <tr v-for="(m,i) in ReserveList.records" :key="i">
                              <td>
                                <div class="w-100 height-30">
                                  <router-link :to="'/user/order/appointmentdetail?bizId=' + m.bizId" target="_blank">
                                    <h6 class="float-left">订单编号：{{m.id}}</h6>
                                  </router-link>
                                  <router-link v-if="m.reserveStatus==0" :to="'/user/order/appointmentdetail?bizId=' + m.bizId" target="_blank">
                                    <span class="badge badge-danger f-14 ml-2" >立即支付</span>
                                  </router-link>
                                  <span class="badge badge-warning f-14 ml-2 cursor-hand" v-if="m.reserveStatus==1"  @click="payreserve(m.id)">确定完成</span>
                                  <span class="badge badge-secondary f-14 ml-2 text-white cursor-hand"  v-if="m.reserveStatus==0 || m.reserveStatus==1" @click="cancelReserve(m.id)">取消预约</span>
                                </div>
                                <div class=" clearfix w-100">
                                  <div class="float-left">
                                    <van-image
                                        width="84"
                                        height="62"
                                        :src="m.userNeedsFiles[0].fileUrl"
                                        radius="8"
                                        fit="cover"
                                    />
                                  </div>
                                  <div class="float-left ml-3">
                                    <router-link :to="'/user/order/appointmentdetail?bizId=' + m.bizId" target="_blank">
                                    <span>{{m.title}}</span>
                                    </router-link>
                                    <div class="text-gray-777">
                                      <span class="text-secondary">带看价格：{{m.prepaid}}元</span>
                                    </div>
                                    <div class="text-secondary">
                                      <span>预约信息：{{m.contacts}}</span>
                                      <span class="ml-2">{{m.contactNumber}}</span>
                                      <span class="ml-2">{{m.reserveDate }}</span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="mt-1">{{m.createdAt}}</div>
                              </td>
                              <td>
                                <h5>
                                  <span class="text-secondary f-12">{{getreserveStatusValue(m.reserveStatus)}}</span>
                                </h5>
                              </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                              <td colspan="3">
                                <div class="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                                  <div class="text-gray-777">没有找到预约带看信息</div>
                                </div>
                              </td>
                            </tr>
                            </tbody>

                          </table>
                        </div>
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                          <div></div>
                          <div>
                            <div v-if="ReserveList && ReserveList.total > ReserveList.size"
                                 class="my-4 d-flex justify-content-center">
                              <b-pagination @change="onPageChange" :total-rows="ReserveList.total"
                                            :per-page="ReserveList.size" v-model="yydkpara.current"></b-pagination>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- end card-body-->
                    </div>
                  </div>
                </b-tab>
                <b-tab title="土地交易">
                  <div class="w-100">
                    <div class="clearfix mt-4 d-flex align-items-center screen">
                      <div class="float-left">下单时间：</div>
                      <div class="float-left ml-2">
                        <b-form-input type="date" v-model="tdjypara.param.startTime"></b-form-input>
                      </div>
                      <div class="float-left ml-2 mr-2">至</div>
                      <div class="float-left">
                        <b-form-input type="date" v-model="tdjypara.param.endTime"></b-form-input>
                      </div>
                      <div class="float-left ml-3">
                        <a class="ml-3" href="javascript:;" @click="createBeginDate(1,2)"
                           :class="{'active':tdjyyues==1}">近一月</a>
                        <a class="ml-3" href="javascript:;" @click="createBeginDate(3,2)"
                           :class="{'active':tdjyyues==3}">近三月</a>
                        <a class="ml-3" href="javascript:;" @click="createBeginDate(6,2)"
                           :class="{'active':tdjyyues==6}">近半年</a>
                        <a class="ml-3" href="javascript:;" @click="createBeginDate(12,2)"
                           :class="{'active':tdjyyues==12}">近一年</a>
                      </div>
                    </div>
                    <div class="clearfix mt-2 d-flex align-items-center screen">
                      <div class="float-left">订单状态：</div>
                      <div class="float-left ml-2">
                        <div class="row p-0 m-0">
                          <a href="javascript:;" class="mr-3 px-2 py-1"
                             @click="tdjypara.param.bizStatus = '', onScreentdjyClick('orderStatus','')"
                             :class="{'active': tdjypara.param.bizStatus == ''}">全部</a>
                          <a href="javascript:;" @click="onScreentdjyClick('orderStatus',v.id)"
                             v-for="v in dict.orderStatus" :key="v.id"
                             class="mr-3 px-2 py-1"
                             :class="{'active':tdjypara.param.bizStatus==v.id }">{{ v.text }}</a>
                        </div>
                      </div>
                    </div>


                    <div class="card border-0 mt-3 mb-3">
                      <div class="card-body p-0">
                        <div class="table-responsive">
                          <table class="table table-centered border mb-0">
                            <thead class="table-light">
                            <tr>
                              <th class="w-70">订单信息</th>
                              <th>下单时间</th>
                              <th>状态</th>
                            </tr>
                            </thead>
                            <tbody v-if="OrderList.records && OrderList.records.length > 0">
                            <tr v-for="(m,i) in OrderList.records" :key="i">
                              <td>
                                <div class="clearfix">
                                  <router-link :to="'/user/order/detail?bizId=' + m.bizId">
                                    <h6 class="float-left">订单编号：{{m.id}}</h6>
                                  </router-link>
                                  <router-link v-if="m.bizStatus == 0" :to="'/user/order/detail?bizId=' + m.bizId">
                                    <span class="badge badge-danger f-14 ml-2">立即支付</span>
                                  </router-link>
                                  <span class="badge badge-secondary f-14 ml-2" v-if="m.bizStatus!=3" @click="cancelOrder(m.id)">取消订单</span>
                                </div>
                                <div class="mt-2 clearfix">
                                  <div class="float-left">
                                    <van-image
                                        width="84"
                                        height="62"
                                        :src="m.userNeedsFiles[0].fileUrl"
                                        radius="8"
                                        fit="cover"
                                    />
                                  </div>
                                  <div class="float-left ml-3">
                                    <span>{{m.title}}</span>
                                    <div class="text-secondary">
                                      <span>服务费：免费</span>
                                    </div>
                                    <div class="text-secondary">
                                      <span>卖方信息：{{m.userNeedsForLet.contacts}}</span>
                                      <span class="ml-2">{{m.userNeedsForLet.contactNumber}}</span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="mt-1">{{m.createdAt}}</div>
                              </td>
                              <td>
                                <h5>
                                  <span class="text-secondary f-12">{{getorderStatusValue(m.bizStatus)}}</span>
                                </h5>

                              </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                              <td colspan="3">
                                <div class="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                                  <div class="text-gray-777">没有找到土地交易信息</div>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                          <div></div>
                          <div>
                            <div v-if="OrderList && OrderList.total > OrderList.size"
                                 class="my-4 d-flex justify-content-center">
                              <b-pagination @change="onPageOrderChange" :total-rows="OrderList.total"
                                            :per-page="OrderList.size" v-model="tdjypara.current"></b-pagination>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- end card-body-->
                    </div>
                  </div>
                </b-tab>

                <b-tab title="农机具交易">
                  <div class="w-100">
                    <div class="clearfix mt-4 d-flex align-items-center screen">
                      <div class="float-left">下单时间：</div>
                      <div class="float-left ml-2">
                        <b-form-input type="date" v-model="para.param.startDate"></b-form-input>
                      </div>
                      <div class="float-left mr-2">至</div>
                      <div class="float-left">
                        <b-form-input type="date" v-model="para.param.endDate"></b-form-input>
                      </div>
                      <div class="float-left ">
                        <a class="ml-3" href="javascript:;" @click="createBeginDate(1,3)"
                           :class="{'active':yues==1}">近一月</a>
                        <a class="ml-3" href="javascript:;" @click="createBeginDate(3,3)"
                           :class="{'active':yues==3}">近三月</a>
                        <a class="ml-3" href="javascript:;" @click="createBeginDate(6,3)"
                           :class="{'active':yues==6}">近半年</a>
                        <a class="ml-3" href="javascript:;" @click="createBeginDate(12,3)"
                           :class="{'active':yues==12}">近一年</a>
                      </div>
                    </div>
                    <div class="clearfix mt-2 d-flex align-items-center screen">
                      <div class="float-left">订单状态：</div>
                      <div class="float-left ml-2" style="width:840px">
                        <div class="row p-0 m-0">
                          <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(1)"
                             :class="{'active':type==1}">全部</a>
                          <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(2)"
                             :class="{'active':type==2}">待支付</a>
                          <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(3)"
                             :class="{'active':type==3}">已失效</a>
                          <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(4)"
                             :class="{'active':type==4}">签约中</a>
                          <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(5)"
                             :class="{'active':type==5}">买方已确认</a>
                          <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(6)"
                             :class="{'active':type==6}">卖方已确认</a>
                          <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(7)"
                             :class="{'active':type==7}">已签约</a>
                          <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(8)"
                             :class="{'active':type==8}">卖方终止合约</a>
                          <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(9)"
                             :class="{'active':type==9}">买方终止合约</a>
                          <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(10)"
                             :class="{'active':type==10}">终止交易</a>
                          <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(11)"
                             :class="{'active':type==11}">已完成</a>
                          <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(12)"
                             :class="{'active':type==12}">已失效</a
                          >
                        </div>
                      </div>
                    </div>

                    <div class="card border-0 mt-3 mb-3">
                      <div class="card-body p-0">
                        <div class="table-responsive">
                          <table class="table table-centered border mb-0">
                            <thead class="table-light">
                            <tr>
                              <th class="w-70">订单信息</th>
                              <th>下单时间</th>
                              <th>状态</th>
                            </tr>
                            </thead>
                            <tbody>
                            <!--                        <tr v-for="i in 10" :key="i">
                                                      <td>
                                                        <h6 class="float-left">订单编号：3484858837877273466</h6>
                                                        <span class="badge badge-danger f-14 ml-2">立即支付</span>
                                                        <span class="badge badge-warning f-14 ml-2 text-white">上传合同</span>
                                                         <span class="badge badge-warning f-14 ml-2 text-white">申请保证金退还</span>
                                                        <span class="badge badge-secondary f-14 ml-2">取消订单</span>
                                                        <div class="mt-2 clearfix">
                                                          <div class="float-left">
                                                            <img src="https://fc6tn.baidu.com/it/u=3030194471,2046924699&fm=202" class="width-80 rounded"/>
                                                          </div>
                                                          <div class="float-left ml-3">
                                                             <h6>
                                                                  <b-badge v-if="i == 1" class="float-left mr-2" variant="success">出租</b-badge>
                                                                  <b-badge v-else class="float-left mr-2" variant="warning">出售</b-badge>
                                                                  {{ i== 1 ? '呼伦贝尔额尔古纳市 收割机 0.7元/平方米 出租' : '呼伦贝尔额尔古纳市 二手 15年 保田收割机'}}
                                                              </h6>
                                                              <div class="text-gray-777">
                                                                <span v-if="i == 1">价格：0.7元/平方米</span>
                                                                <span v-else>价格：17万元</span>
                                                                <span class="ml-2">保证金：2000元</span>
                                                              </div>
                                                               <div class="text-secondary">
                                                                <span>卖方信息：汪先生</span>
                                                                <span class="ml-2">13874689975</span>
                                                              </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div class="mt-1">2021-07-31 10:29</div>
                                                       </td>
                                                      <td>
                                                         <h5>
                                                          <span class="text-secondary f-12">待支付</span>
                                                        </h5>

                                                      </td>
                                                    </tr>-->

                            <tr>
                              <td colspan="3">
                                <div class="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                                  <div class="text-gray-777">没有找到农机具订单</div>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                          <div></div>
                          <div>
                            <b-pagination
                                class="mt-1 mb-1"
                                :total-rows="10"
                                :per-page="1"
                                aria-controls="my-table"
                            ></b-pagination>
                          </div>
                        </div>
                      </div>
                      <!-- end card-body-->
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>

          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "order",
  data() {
    return {
      list: [],
      yydkpara: {
        param: {
          startDate: "",
          endDate:"",
          reserveStatus: ""
        },
        size: 10,
        current: 1,
      },
      tdjypara: {
        param: {
          startTime: "",
          endTime: "",
          bizStatus:"",
        },
        size: 10,
        current: 1,
      },
      para: {
        param: {
          startDate: "",
          endDate: ""
        },
      },
      yydkyues:'',
      tdjyyues:'',
      yues: '',
      ReserveList: [], //预约待看集合
      OrderList:[],//合同集合
      type: 1,
      bzjtype: 1
    };
  },
  computed:{
    ...mapGetters(["dict"]),
    getreserveStatusValue () {
      return function(id) {
        if(this.dict && this.dict.reserveStatus) {
          let m = this.dict.reserveStatus.find(x => x.id == id)
          if(m) {
            return m.text;
          }
        }
        return ''
      }
    },
    getorderStatusValue () {
      return function(id) {
        if(this.dict && this.dict.orderStatus) {
          let m = this.dict.orderStatus.find(x => x.id == id)
          if(m) {
            return m.text;
          }
        }
        return ''
      }
    },
  },
  methods: {
    onPageChange(i) {
      this.yydkpara.current = i;
      this.findReserve();
    },
    onPageOrderChange(i) {
      debugger;
      this.tdjypara.current = i;
      this.findOrderList();
    },
    clicktype(type) {
      debugger;
      this.type = type;
    },
    clickbzjtype(bzjtype) {
      this.bzjtype = bzjtype;
    },
    //获取当前日期几个月内的数据
    createBeginDate(yues,type) {
      this.yues = yues;
      var date = new Date();
      date.setMonth(date.getMonth() - yues);
      date.toLocaleDateString()
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d

      var dqdate = new Date();
      dqdate.toLocaleDateString()
      var b = dqdate.getFullYear()
      var e = dqdate.getMonth() + 1
      e = e < 10 ? ('0' + e) : e
      var q = dqdate.getDate()
      q = q < 10 ? ('0' + q) : q

      const dqtime = b + '-' + e + '-' + q;
      if (type==1) {
        this.yydkyues=yues;
        this.yydkpara.param.startDate = time;
        this.yydkpara.param.endDate = dqtime;
        this.findReserve();
      }
      if (type==2) {
        debugger;
        this.tdjyyues=yues;
        this.tdjypara.param.startTime = time;
        this.tdjypara.param.endTime = dqtime;
        this.findOrderList();
      }
      if (type==3) {
        debugger;
        this.para.param.startDate = time;
        this.para.param.endDate = dqtime;
      }

    },

    findReserve() {
      this.$http.post(this.$api.reserve.findReserveRecordList,this.yydkpara).then((res) => {
        debugger
        this.ReserveList = res.result;
      });
    },
    onScreenyydkClick(type, id) {
      this.yydkpara.current = 1;
      switch (type) {
        case 'reserveStatus':
          this.yydkpara.param.reserveStatus = id;
          break;

      }
      this.findReserve();

    },

    findOrderList(){
      //查询订单集合
      this.$http.post(this.$api.order.findUserOrder,this.tdjypara).then((res) => {
        this.OrderList = res.result;

      });
    },
    onScreentdjyClick(type, id) {
      this.tdjypara.current = 1;
      switch (type) {
        case 'orderStatus':
          this.tdjypara.param.bizStatus = id;
          break;

      }
      this.findOrderList();

    },
    addMachinery() {
      let u = this.$router.resolve({path: '/user/land/add'});
      window.open(u.href, '_blank');
    },
    payreserve(id){
      this.$http.post(this.$api.reserve.payForReserve+id).then((res) => {
      this.findReserve();
      });
    },
    cancelReserve(id){
      this.$http.post(this.$api.reserve.cancelReserve+id).then((res) => {
        this.findReserve();
      });
    },
    cancelOrder(id){
      this.$http.post(this.$api.order.cancelOrder+id).then((res) => {
        debugger;
        this.findOrderList();
      });
    }

  },
  created() {
    this.findReserve();
    this.findOrderList();
  }
};
</script>

<style scoped>
@import url("~@/assets/css/user.css");


.screen .active {
  background: #00cc00;
  padding: 5px;
  color: #FFFFFF;
  border-radius: 4px;
}


</style>
